<template>
  <div class="toolbar">
    <link rel="stylesheet" type="text/css" href="//at.alicdn.com/t/font_598462_3xve1872wizzolxr.css" />
    <i class="command iconfont icon-undo" :title="$t('projectlist.btn.revoke')" :class="undoList.length > 0 ? '' : 'disable'" @click="handleUndo"></i>
    <i class="command iconfont icon-redo" :title="$t('projectlist.btn.redo')" :class="redoList.length > 0 ? '' : 'disable'" @click="handleRedo"></i>
    <span class="separator"></span>
    <!-- <i data-command="copy" class="command iconfont icon-copy-o disable" title="复制"></i>
    <i data-command="paste" class="command iconfont icon-paster-o disable" title="粘贴"></i>-->
    <i data-command="delete" class="command iconfont icon-delete-o" :title="$t('projectlist.btn.delete')" :class="selectedItem ? '' : 'disable'"
      @click="handleDelete"></i>
    <span class="separator"></span>
    <i data-command="zoomIn" class="command iconfont icon-zoom-in-o" :title="$t('projectlist.btn.enlarge')"  @click="handleZoomIn"></i>
    <i data-command="zoomOut" class="command iconfont icon-zoom-out-o" :title="$t('projectlist.btn.narrow')"  @click="handleZoomOut"></i>
    <i data-command="autoZoom" class="command iconfont icon-fit" :title="$t('projectlist.btn.Fittocanvas')"  @click="handleAutoZoom"></i>
    <i data-command="resetZoom" class="command iconfont icon-actual-size-o" :title="$t('projectlist.btn.Actualsize')"  @click="handleResetZoom"></i>
    <span class="separator"></span>
    <i data-command="toBack" class="command iconfont icon-to-back" :class="selectedItem ? '' : 'disable'" :title="$t('projectlist.btn.Postlevel')"
      @click="handleToBack"></i>
    <i data-command="toFront" class="command iconfont icon-to-front" :class="selectedItem ? '' : 'disable'" :title="$t('projectlist.btn.Levelleading')"
      @click="handleToFront"></i>
    <span class="separator"></span>
    <span class="separator"></span>
    <i data-command="multiSelect" class="command iconfont icon-select" :class="multiSelect ? 'disable' : ''" :title="$t('projectlist.btn.Multiplechoice')"
      @click="handleMuiltSelect"></i>
    <i data-command="addGroup" class="command iconfont icon-group" :title="$t('projectlist.btn.Group')" :class="addGroup ? '' : 'disable'"
      @click="handleAddGroup"></i>
    <i data-command="unGroup" class="command iconfont icon-ungroup disable" :title="$t('projectlist.btn.Ungroup')"></i>
    <!-- <el-button @click="consoleData" type="primary">控制台输出数据</el-button> -->

    
    
    <el-button style="margin-left: 30px;"  @click="BStask" >{{$t('projectlist.btn.deploy')}}<i class="el-icon-upload"></i></el-button>
    <el-popover
    placement="top-start"
    trigger="hover"
    :content="$t('projectlist.msg.msg2')">
    <el-button slot="reference" style="margin-left: 30px;" v-if="!runSt" @click="Runtask">{{$t('projectlist.btn.run')}}<i class="el-icon-video-play"></i></el-button>
  </el-popover>

  <el-popover
    placement="top-start"
    trigger="hover"
    :content="$t('projectlist.msg.msg3')">
    <el-button slot="reference" style="margin-left: 30px;"   v-if="runSt" @click="Stoptask">{{$t('projectlist.btn.stop')}}<i class="el-icon-video-pause"></i></el-button>
  </el-popover>
   
    
    <div style="float: right;">

      <el-button type="primary" @click="submitSave(1)">{{$t('projectlist.btn.save')  }}</el-button>
      <el-button style="margin-left: 20px;" @click="ClosedG6">{{$t('projectlist.btn.back')  }}</el-button>

    </div>
  </div>
</template>

<script>
import eventBus from "@/util/eventBus"
import Util from "@antv/g6/src/util";
import { uniqueId, getBox } from "../../utils";
import { SaveTaskGl,runFlow,releaseProjectZip } from "@/api/dataAcquisition";
// SaveTaskGl
export default {
  data() {
    return {
      page: {},
      graph: {},
      redoList: [],
      undoList: [],
      editor: null,
      command: null,
      selectedItem: null,
      multiSelect: false,
      addGroup: false,
      addParms: {
        children: [],
        content: "", //项目名
        delFile: [],
        execid: "",
        id: "",
        name: "",
        objectJson: "",
        pid: "",
        tempname: "",
        zh_name: "",
        g6:{},
      },
      data1:{},
      Olddata:{},
      Oldchildren:{},
      runSt:false,
      // taskId :"",
    };
  },
  created() {
   
    this.runSt = false
    
    this.init();
    this.bindEvent();
    this.$eventBus.$on('eventNameq', (res)=>{
     this.data1 = res.data.data
     if(res.data.data.g6){
      // this.Olddata = JSON.parse(JSON.stringify(res.data.data.g6.nodes))  ;  
      // this.Olddata = JSON.parse(res.data.data.g6.nodes)
      this.Olddata = res.data.data.g6.nodes
     }else{
      this.Olddata = []
     }
     if(res.data.data.children){
      this.Oldchildren = JSON.parse(JSON.stringify(res.data.data.children));
     }else{
      this.Oldchildren = []
     }
     
     
});
this.$eventBus.$on('runt', (res)=>{
     this.Runtask()
})
  },
  watch: {
    selectedItem(val) {
      if (val && val.length > 1) {
        this.addGroup = true;
      } else {
        this.addGroup = false;
      }
    }
  },
  methods: {
    Runtask(){
      runFlow({id:this.data1.id}).then(res=>{
       this.runSt = true
      })
    },
    Stoptask(){
      this.runSt = false
    },
    BStask(){
      this.submitSave(2)

        

    },
   
    init() {
      const { editor, command } = this.$parent;
      this.editor = editor;
      this.command = command;
    },
    bindEvent() {
      let self = this;
      eventBus.$on("afterAddPage", page => {
        self.page = page;
        self.graph = self.page.graph;
      });
      eventBus.$on("add", data => {
        this.redoList = data.redoList;
        this.undoList = data.undoList;
      });
      eventBus.$on("update", data => {
        this.redoList = data.redoList;
        this.undoList = data.undoList;
      });
      eventBus.$on("delete", data => {
        this.redoList = data.redoList;
        this.undoList = data.undoList;
      });
      eventBus.$on("updateItem", item => {
        this.command.executeCommand("update", [item]);
      });
      eventBus.$on("addItem", item => {
        this.command.executeCommand("add", [item]);
      });
      eventBus.$on("nodeselectchange", () => {
        this.selectedItem = this.graph.findAllByState("node", "selected");
        this.selectedItem = this.selectedItem.concat(
          ...this.graph.findAllByState("edge", "selected")
        );
      });
      eventBus.$on("deleteItem", () => {
        this.handleDelete();
      });
      eventBus.$on("muliteSelectEnd", () => {
        this.multiSelect = false;
        this.selectedItem = this.graph.findAllByState("node", "selected");
      });
    },
    handleUndo() {
      if (this.undoList.length > 0) this.command.undo();
    },
    handleRedo() {
      if (this.redoList.length > 0) this.command.redo();
    },
    handleDelete() {
      if (this.selectedItem.length > 0) {
        this.command.executeCommand("delete", this.selectedItem);
        this.selectedItem = null;
      }
    },
    getFormatPadding() {
      return Util.formatPadding(this.graph.get("fitViewPadding"));
    },
    getViewCenter() {
      const padding = this.getFormatPadding();
      const graph = this.graph;
      const width = this.graph.get("width");
      const height = graph.get("height");
      return {
        x: (width - padding[2] - padding[3]) / 2 + padding[3],
        y: (height - padding[0] - padding[2]) / 2 + padding[0]
      };
    },
    handleZoomIn() {
      const currentZoom = this.graph.getZoom();
      this.graph.zoomTo(currentZoom + 0.5, this.getViewCenter());
    },
    handleZoomOut() {
      const currentZoom = this.graph.getZoom();
      this.graph.zoomTo(currentZoom - 0.5, this.getViewCenter());
    },
    handleToBack() {
      if (this.selectedItem && this.selectedItem.length > 0) {
        this.selectedItem.forEach(item => {
          item.toBack();
          this.graph.paint();
        });
      }
    },
    handleToFront() {
      if (this.selectedItem && this.selectedItem.length > 0) {
        this.selectedItem.forEach(item => {
          if (item.getType() === "edge") {
            // const nodeGroup = this.graph.get("nodeGroup");
            // const edgeGroup = item.get("group");
            // nodeGroup.toFront();
            // edgeGroup.toFront()
          } else {
            item.toFront();
          }

          this.graph.paint();
        });
      }
    },
    handleAutoZoom() {
      this.graph.fitView(20);
    },
    handleResetZoom() {
      this.graph.zoomTo(1, this.getViewCenter());
    },
    handleMuiltSelect() {
      this.multiSelect = true;
      this.graph.setMode("mulitSelect");
    },
    handleAddGroup() {
      //TODO 这部分等阿里更新Group之后添加
      // const model = {
      //   id: "group" + uniqueId(),
      //   title: "新建分组"
      // };
      // // this.command.executeCommand("add", "group", model);
      // this.selectedItem.forEach(item => {
      //   console.log(item);
      // });

      //this.getPosition(this.selectedItem);
    },
    getPosition(items) {
      const boxList = [];
      items.forEach(item => {
        const box = item.getBBox();
        boxList.push(getBox(box.x, box.y, box.width, box.height));
      });
      let minX1, minY1, MaxX2, MaxY2;
      boxList.forEach(box => {
        if (typeof minX1 == "undefined") {
          minX1 = box.x1;
        }
        if (typeof minY1 == "undefined") {
          minY1 = box.y1;
        }
        if (typeof MaxX2 == "undefined") {
          MaxX2 = box.x2;
        }
        if (typeof MaxY2 == "undefined") {
          MaxY2 = box.y2;
        }
        if (minX1 > box.x1) {
          minX1 = box.x1;
        }
        if (minY1 > box.y1) {
          minY1 = box.y1;
        }
        if (MaxX2 < box.x2) {
          MaxX2 = box.x2;
        }
        if (MaxY2 < box.y2) {
          MaxY2 = box.y2;
        }
      });
      const width = MaxX2 - minX1,
        height = MaxY2 - minY1,
        x = minX1 + width / 2,
        y = minY1 + height / 2,
        id = "team" + uniqueId();
      const model = {
        id: id,
        width,
        height,
        x,
        y,
        shape: "teamNode"
      };
      this.command.executeCommand("add", model);
      // const item = this.graph.findById(id);
      // item.get("group").toBack();
      // const edgeGroup = this.graph.get("edgeGroup");
      // edgeGroup.toFront();
      // this.graph.paint();
    },

    consoleData() {
      console.log(this.graph.save());
    },

    //返回，关闭g6
    ClosedG6(){
     
      this.$eventBus.$emit('CloseG6',"ss")
      this.runSt = false
    },
    // addParms

    


    submitSave(e){   
      let aa =JSON.parse(JSON.stringify(this.graph.save()));   
      // this.addParms.children = aa.nodes
     if(aa.nodes){
      this.addParms.children = []
       aa.nodes.forEach(element => {
        this.addParms.children.push({
          nodeid:element.id,
          dbtype: "",
          fromdb: "",
          id: "",
          is_dir: "false",
          level: 0,
          links: [],
          name: element.name,
          namesuffix:element.name+"."+element.suffix,
          pid: this.data1.id,
          // selectsql: "",
          center:element.center,
          suffix: element.suffix,
          tempname: element.label +"("+element.suffix +")",
          xindex: 0,
          xtotal: 0,
          zh_name: element.label,
        })
      });
        //新增节点 删掉id
      let  add = []
         if(this.Oldchildren){
          add = this.addParms.children.filter(item => !this.Oldchildren.some(ele=>ele.nodeid===item.nodeid));

          //原有id赋值id
          this.addParms.children.forEach(element => {
            this.Oldchildren.forEach(element2 => {
               if(element.nodeid == element2.nodeid){
                element.id = element2.id
               }
            });
          });
          if(add){
            this.addParms.children.forEach(element => {
           add.forEach(element2 => {
              if(element.nodeid == element2.nodeid){
                element.id = undefined
              }
           });
          });
          }
         }else{
          this.addParms.children.forEach(element => {
            element.id = undefined
          });
         }
         

     } 

     //删除的节点

       if(this.Oldchildren){
        this.addParms.delFile = this.Oldchildren.filter(item => !this.addParms.children.some(ele=>ele.nodeid===item.nodeid)); 
       }



       //处理job节点
        if(this.addParms.children){
          this.addParms.children.forEach(element => {
            if(element.suffix == "job"){
              // if(element.center){
               if(element.center.slice(0,21) != "type=command\ncommand=") {
                element.center = "type=command\ncommand="+element.center
               }
               if(element.center.indexOf("dependencies") == -1){
                
                element.center = element.center + "\ndependencies="
                if(aa.edges){
                  aa.edges.forEach(element2 => {
                    if(element2.targetId == element.nodeid){
                        let gg = []
                     
                      
                      this.addParms.children.forEach(element3 => {
                         if(element2.sourceId == element3.nodeid){
                          gg.push(element3.name) 
                         }
                      }); 

                      element.center =  element.center + gg.toString()+","
                    }
                  });
                }
               }


              //  str.slice(0, str.length - 1)
              if(element.center[element.center.length-1]==","){
                element.center = element.center.slice(0, element.center.length - 1)
              }
             
               
                 
              // }
              // else{
              //   element.center = "type=command\ncommand="
              // }


            }
          });
        }


     
     

      


      this.addParms.content = this.data1.content
      this.addParms.execid = this.data1.execid
      this.addParms.id = this.data1.id
      this.addParms.objectJson = this.data1.objectJson
      this.addParms.pid = this.data1.pid
      this.addParms.name = this.data1.name
      this.addParms.tempname = this.data1.tempname
      this.addParms.zh_name = this.data1.zh_name
      this.addParms.g6 = this.graph.save()
      SaveTaskGl(this.addParms).then(res=>{
        if(res.status == 200){
        console.log(res,"ssdasdasd");
        if(e == 1){
          this.$eventBus.$emit('CloseG6',res)
        }

        if(e==2){
          releaseProjectZip({id:this.data1.id}).then(res=>{
          // console.log(res);
          this.$message({
          message: res.data.msg,
          type: 'success'
        });
         })
        }
       
        
      }
      })



    }




  }
};
</script>


<style scoped>
.toolbar {
  box-sizing: border-box;
  /* padding: 8px 0px; */
  width: 100%;
  border: 1px solid #e9e9e9;
  /* height: 42px; */
  z-index: 3;
  box-shadow: 0px 8px 12px 0px rgba(0, 52, 107, 0.04);
  position: absolute;
}

.toolbar .command:nth-of-type(1) {
  margin-left: 24px;
}

.toolbar .command {
  box-sizing: border-box;
  width: 27px;
  height: 27px;
  margin: 0px 6px;
  border-radius: 2px;
  padding-left: 4px;
  display: inline-block;
  border: 1px solid rgba(2, 2, 2, 0);
}

.toolbar .command:hover {
  cursor: pointer;
  border: 1px solid #e9e9e9;
}

.toolbar .disable {
  color: rgba(0, 0, 0, 0.25);
}

.toolbar .separator {
  margin: 4px;
  border-left: 1px solid #e9e9e9;
}
</style>