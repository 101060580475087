var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "navigator" } }, [
    _c("div", { staticClass: "pannel-title" }, [
      _vm._v(_vm._s(_vm.$t("projectlist.btn.Navigator"))),
    ]),
    _c("div", {
      ref: "minimap",
      staticClass: "minimap",
      attrs: { id: "minimap" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }