var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detailpannel" },
    [
      _c("div", [
        _vm.status == "node-selected"
          ? _c(
              "div",
              { staticClass: "pannel", attrs: { id: "node_detailpannel" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "pannel-title",
                    staticStyle: {
                      color: "skyblue",
                      "font-size": "14px",
                      "text-align": "center",
                      "background-color": "#fff",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("projectlist.btn.ParameterSetting")))]
                ),
                _c(
                  "div",
                  {
                    staticClass: "block-container",
                    staticStyle: { "font-size": "15px" },
                  },
                  [
                    _c("el-row", { staticStyle: { "padding-top": "5px" } }, [
                      _vm._v(_vm._s(_vm.$t("projectlist.btn.name")) + " "),
                    ]),
                    _c(
                      "el-row",
                      { staticStyle: { "padding-top": "5px" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.node.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.node, "name", $$v)
                            },
                            expression: "node.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("el-row", { staticStyle: { "padding-top": "5px" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("projectlist.seetabs.editlabel.Chinesename")
                        ) + " "
                      ),
                    ]),
                    _c(
                      "el-row",
                      { staticStyle: { "padding-top": "5px" } },
                      [
                        _c("el-input", {
                          on: { change: _vm.handleChangeName },
                          model: {
                            value: _vm.node.label,
                            callback: function ($$v) {
                              _vm.$set(_vm.node, "label", $$v)
                            },
                            expression: "node.label",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticStyle: { "padding-top": "5px" } },
                      [
                        _c("el-button", {
                          attrs: { size: "mini", icon: "el-icon-edit" },
                          on: { click: _vm.opDailog },
                        }),
                        _vm.showD
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: { click: _vm.opDailog2 },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("projectlist.btn.Taskbuilding"))
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("el-row", { staticStyle: { "padding-top": "5px" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("projectlist.btn.DocumentContent")) + " "
                      ),
                    ]),
                    _c(
                      "el-row",
                      { staticStyle: { "padding-top": "5px" } },
                      [
                        _c("el-input", {
                          ref: "ipt",
                          attrs: { type: "textarea", rows: 3 },
                          model: {
                            value: _vm.node.center,
                            callback: function ($$v) {
                              _vm.$set(_vm.node, "center", $$v)
                            },
                            expression: "node.center",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: _vm.$t("projectlist.listedit.edit"),
                          visible: _vm.dialogVisible,
                          width: "40%",
                          "before-close": _vm.handleClose,
                          "append-to-body": "",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.dialogVisible = $event
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("el-row", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("projectlist.btn.DocumentContent")
                                ) + " "
                              ),
                            ]),
                            _c("el-row", [_vm._v("    ")]),
                            _c(
                              "el-row",
                              [
                                _c("el-input", {
                                  attrs: { type: "textarea", rows: 8 },
                                  model: {
                                    value: _vm.node.center,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.node, "center", $$v)
                                    },
                                    expression: "node.center",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.dialogVisible = false
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("cancelText")))]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    _vm.dialogVisible = false
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("submitText")))]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.status === "canvas-selected"
          ? _c(
              "div",
              { staticClass: "pannel", attrs: { id: "canvas_detailpannel" } },
              [
                _c("div", { staticClass: "pannel-title" }, [
                  _vm._v(_vm._s(_vm.$t("projectlist.btn.canvas"))),
                ]),
                _c(
                  "div",
                  { staticClass: "block-container" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        on: { change: _vm.changeGridState },
                        model: {
                          value: _vm.showGrid,
                          callback: function ($$v) {
                            _vm.showGrid = $$v
                          },
                          expression: "showGrid",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("projectlist.btn.Gridalignment")))]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("projectlist.btn.Taskbuilding"),
            visible: _vm.dialogVisible2,
            width: "70%",
            "before-close": _vm.handleClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible2 = $event
            },
            close: _vm.closeD,
          },
        },
        [
          _vm.dialogVisible2 ? _c("div", [_c("offLine")], 1) : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible2 = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cancelText")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible2 = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("submitText")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }