var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { width: _vm.width }, attrs: { id: "mountNode" } },
    [
      _c(
        "div",
        { staticClass: "editor" },
        [
          _c("context-menu"),
          _c("toolbar"),
          _c("div", { staticStyle: { height: "42px" } }),
          _c(
            "div",
            { staticClass: "bottom-container" },
            [
              _c("item-panel"),
              _c("detail-panel"),
              _c("minimap"),
              _c("page", {
                attrs: { height: _vm.height, width: _vm.width, data: _vm.data },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("Flow"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }