var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { attrs: { id: "mountNode" } }),
    _c("div", { staticStyle: { display: "none" } }, [_c("gg")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }