// import eventBus from "../utils/eventBus";
import eventBus from "@/util/eventBus"
import  Vue from '../../../../../../main'
export default {
    getEvents() {
        return {
            'node:contextmenu': 'onContextmenu',
            'mousedown': 'onMousedown',
            'canvas:click':'onCanvasClick'
        };
    },
    onContextmenu(e) {
        Vue.$eventBus.$emit('contextmenuClick1',e)
    },
    onMousedown(e) {
        Vue.$eventBus.$emit('mousedown',e)
    },
    onCanvasClick(e){
        eventBus.$emit('canvasClick',e)
    }

};
