<template>
  <div>

    <!-- <div style="display: flex;height: 30px;">
      <el-input style="height: 30px;"></el-input>
      <el-button icon="el-icon-search" size="mini" style="width: 30px;">  </el-button>
      <el-button icon="el-icon-plus" size="mini" style="width: 30px;text-align: center;"></el-button>

    </div> -->




    <el-tree :data="data1" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
  <ul>
    <li
      v-for="(item,index) in list"
      :key="index"
      class="getItem"
      :data-shape="item.shape"
      :data-type="item.type"
      :data-size="item.size"
      draggable
      @dragstart="handleDragstart"
      @dragend="handleDragEnd($event,item)"
    >
    <!-- <el-popover
    placement="right"
    :title=item.name
    width="200"
    trigger="hover"
    >
    <span>文档</span>
    <a href="www.baidu.com">2222222222</a>

    <div slot="reference">
      <span   class="pannel-type-icon" :style="{background:'url('+item.image+')'}"></span>
      {{item.name}}
    </div>

  </el-popover> -->

  <span   class="pannel-type-icon" :style="{background:'url('+item.image+')'}"></span>  <span>{{item.name}}</span>

    </li>
  </ul>
</div>
</template>

<script>
import eventBus from "@/util/eventBus"
// import okSvg from "@/assets/icons/ok.svg";
export default {
  data() {
    return {
      data1:[{
        name:"",
        children:[]
      }],

      page: null,
      command: null,
      offsetX: 0,
      offsetY: 0,
      list: [
        {
          id2:4,
          name: "py",
          label: "py组件",
          suffix: "py",
          size: "160*34",
          type: "node",
          center:"",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          image:
            "https://gw.alipayobjects.com/zos/rmsportal/czNEJAmyDpclFaSucYWB.svg",
          // stateImage: okSvg,
          // inPoints: [[0, 0.5]],
          // outPoints: [[1, 0.5]]
        },

        {
          id2:0,
          name: "sql",
          label: "sql组件",
          suffix: "sql",
          size: "160*34",
          type: "node",
          center:"",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          image:
            "https://gw.alipayobjects.com/zos/rmsportal/czNEJAmyDpclFaSucYWB.svg",
          // stateImage: okSvg,
          // inPoints: [[0, 0.5]],
          // outPoints: [[1, 0.5]]
        },

        {
          id2:1,
          name: "sh",
          label: "sh组件",
          size: "160*34",
          type: "node",
          suffix: "sh",
          center:"",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          image:
            "https://gw.alipayobjects.com/zos/rmsportal/czNEJAmyDpclFaSucYWB.svg",
          // stateImage: okSvg,
          // inPoints: [[0, 0.5]],
          // outPoints: [[1, 0.5]]
        },


        // {
        //   id2:2,
        //   name: "json",
        //   label: "json组件",
        //   suffix: "json",
        //   size: "160*34",
        //   type: "node",
        //   center:"",
        //   x: 0,
        //   y: 0,
        //   shape: "customNode",
        //   color: "#1890ff",
        //   image:
        //     "https://gw.alipayobjects.com/zos/rmsportal/czNEJAmyDpclFaSucYWB.svg",
        //   // stateImage: okSvg,
        //   // inPoints: [[0, 0.5]],
        //   // outPoints: [[1, 0.5]]
        // },

        {
          id2:3,
          name: "job",
          label: "job组件",
          suffix: "job",
          size: "160*34",
          type: "node",
          center:"",
          x: 0,
          y: 0,
          shape: "customNode",
          color: "#1890ff",
          image:
            "https://gw.alipayobjects.com/zos/rmsportal/czNEJAmyDpclFaSucYWB.svg",
          // stateImage: okSvg,
          inPoints: [[0, 0.5]],
          outPoints: [[1, 0.5]]
        },





      ],
      defaultProps: {
          children: 'children',
          label: 'name'
        }
    };
  },
  created() {
    this.bindEvent();
  },
  mounted(){



this.$eventBus.$on('eventNameq', (res)=>{
     this.data1[0].name = res.data.data.tempname
     this.data1[0].children = res.data.data.children
})

  },
  methods: {
    handleDragstart(e) {
      this.offsetX = e.offsetX;
      this.offsetY = e.offsetY;
    },
    handleDragEnd(e, item) {
      let data = {};
      Object.assign(data, item);
      data.offsetX = this.offsetX;
      data.offsetY = this.offsetY;
      if (this.page) {
        const graph = this.page.graph;
        // const size = e.target.dataset.size.split("*");
        const xy = graph.getPointByClient(e.x, e.y);
        data.x = xy.x;
        data.y = xy.y;
        data.size = item.size.split("*");
        data.type = "node";
        this.command.executeCommand("add", [data]);
      }
    },
    bindEvent() {
      eventBus.$on("afterAddPage", page => {
        this.page = page;
        this.command = page.command;
      });
    }
  }
};
</script>

<style scoped>
.itempannel {
  height: 100%;
  position: absolute;
  left: 0px;
  z-index: 2;
  background: #f7f9fb;
  width: 200px;
  padding-top: 8px;
  border-right: 1px solid #e6e9ed;
}
.itempannel ul {
  padding: 0px;
  padding-left: 16px;
}
.itempannel li {
  color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  width: 150px;
  height: 28px;
  line-height: 26px;
  padding-left: 8px;
  border: 1px solid rgba(0, 0, 0, 0);
  list-style-type: none;
}
.itempannel li:hover {
  background: white;
  border: 1px solid #ced4d9;
  cursor: move;
}

.itempannel .pannel-type-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
</style>
