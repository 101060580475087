var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-tree", {
        attrs: { data: _vm.data1, props: _vm.defaultProps },
        on: { "node-click": _vm.handleNodeClick },
      }),
      _c(
        "ul",
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "getItem",
              attrs: {
                "data-shape": item.shape,
                "data-type": item.type,
                "data-size": item.size,
                draggable: "",
              },
              on: {
                dragstart: _vm.handleDragstart,
                dragend: function ($event) {
                  return _vm.handleDragEnd($event, item)
                },
              },
            },
            [
              _c("span", {
                staticClass: "pannel-type-icon",
                style: { background: "url(" + item.image + ")" },
              }),
              _c("span", [_vm._v(_vm._s(item.name))]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }