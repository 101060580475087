<template>
    <div>
        <div id="mountNode"></div>
        <div style="display:none">
            <gg> </gg>
        </div>
          
    </div>
</template>


<script>
import G6 from "@antv/g6/build/g6";
import eventBus from "../../../../util/eventBus"
import gg from "./src/components/G6Editor/index.vue";

export default {
    data() {
        return {
            pageId: "graph-container",
            graph: null,
            data2: {
                "nodes": [],
                "edges": [],
                "groups": []
            }
        };
    },
    components: {
    gg,
   
  },

    created() {

    },
    mounted() {
        this.$nextTick(() => {
            this.init();
        })

        this.$eventBus.$on("sendG6", res => {
            if (res) {
                this.data2 = JSON.parse(res) 
                this.readData()
            }
        })

        eventBus.$on("sendG62", res => {
            if (res) {
                this.data2 = JSON.parse(res) 
                this.readData()
            }
        })


    },
    methods: {
        init() {

            this.graph = new G6.Graph({
                container: 'mountNode', // 指定图画布的容器 id，与第 9 行的容器对应
                // 画布宽高
                width: 1500,
                height: 500,
                modes: {
                    default: ['drag-canvas', 'zoom-canvas']
                }
            });
            // 读取数据
            this.graph.data(this.data2);
            // 渲染图
            this.graph.render();

            this.readData();




        },
        readData() {

            if (this.data2) {
                this.graph.read(
                    this.data2
                )
            }
        }
    }
};
</script>

<style scoped>
.page {
    margin-left: 200px;
    margin-right: 200px;
}
</style>